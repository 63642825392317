@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.where-to-buy {
  padding: rfs-value(45px 15px);

  @include media-breakpoint-up(md) {
    padding: rfs-value(60px 30px);
  }

  h2 {
    text-align: left;
  }
  .title {
    font-size: rfs-value(30px);
    line-height: 1.2;
    text-align: center;
    margin-bottom: rfs-value(12px);

    @include media-breakpoint-up(md) {
      font-size: rfs-value(36px);
    }
  }
  .tabs {
    justify-content: center;
    border: none;
  }

  :global(.nav-item) {
    padding: rfs-value(15px);
    line-height: rfs-value(18px);
  }
  :global(.nav-link) {
    border: none;
    border-bottom: 2px solid transparent;
    color: #404040;
    display: inline-block;
    font-size: rfs-value(12px);
    font-weight: 700;
    letter-spacing: rfs-value(1.2px);
    padding: rfs-value(0 0 1px);
    text-transform: uppercase;
    transition: border-color 0.2s ease-out;

    &:hover,
    &:global(.active) {
      border-color: $nikon-yellow;
      color: #404040;
    }
  }
  svg {
    display: inline-block;
  }
}